import React from "react"
import Layout from "../components/layouts/Layout.js"
import Banner from "../components/Banner.js"

const LegacySupportPlans = () => {
  return (
    <Layout>
      <Banner />
      <h1 style={{ fontSize: "24pt" }}>Legacy Support Plans</h1>
      <div
        style={{
          width: "700px",
          height: "700px",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        <iframe
          style={{
            border: "none",
            width: "100%",
            height: "100%",
            marginLeft: "-1px",
          }}
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRgdhCz5OhK4MhvlUudi2Ts1d8J5wjpPmMnFm8_EpFF_pNwuydHPH1HnUVAQyDLE86Vrgy96LCE-Ki1/pubhtml?widget=false&amp;chrome=false"
          title="Legacy Support Plans"
        ></iframe>
      </div>
    </Layout>
  )
}

export default LegacySupportPlans
